<template>
<header>
<div class="container"> 

<mramik @toggle-add-task="toggleAddTask" title="Moje Ulohy"/>
<div v-show = "showAddTask" >
<AddTask @add-task="addTask"/>
</div>

<Tasks @toggle-reminder="toggleReminder" 
@delete-task= "deleteTask" :tasks="tasks"/> 
</div>
</header>

</template>

<script>
import Mramik from './components/Mramik'
import Tasks from './components/Tasks'
import AddTask from './components/AddTask'
import Task from './components/Task'



export default {
  name: 'App',
  components: {Mramik,Tasks, AddTask,Task
  
  },
  data() {
    return {
      tasks:[],
      showAddTask: false

    }
  },
  methods: {
    toggleAddTask() {
      this.showAddTask = !this.showAddTask

    },
    addTask(task){
      this.tasks = [...this.tasks,  task]
    },
    deleteTask(id) {
      if(confirm('Si si isty?')){
      this.tasks = this.tasks.filter((task) => task.id !== id)
      
      }
      

    },
    toggleReminder(id){
      this.tasks= this.tasks.map((task)=>
      task.id === id ? {...task, reminder: !task.reminder }:task)
    }


  },


  created(){
    this.tasks= [
      {
        id:1,
        text: 'Navsteva Lekara',
        day: '12 marca 14:30',
        reminder: true,
      },
      {
        id:2,
        text:'mama zavolat',
        day: '12 aprila 13:20',
        reminder: true,
      },
      {
        id:3,
        text: 'Silvia ockovanie',
        day: '29 aprila 14:00',
        reminder: true,
      },
           {
        id:4,
        text: 'Dedo ockovanie',
        day: '25 aprila 11:50',
        reminder: true,
      }


    ]
  },
}
</script>

<style>

* {box-sizing: border-box;
margin: 0;
padding: 0;
}


body {
  font-family: 'poppins',sans-serif;
}
.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 9px solid steelblue;
  padding: 30px;
border-radius: 15px;
}

.btn{
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus{
outline: none;

}
</style>
