<template>
<div :key="task.id"  v-for="task in tasks">

   <Task @toggle-reminder="$emit('toggle-reminder',task.id)" 
   @delete-task= "$emit('delete-task', task.id)" :task="task"/>

</div>
    
</template>

<script>
import Task from './Task'
import task from './Task.vue'
export default {
  components: { Task },
    name: 'Tasks',
    props: {
        tasks: Array,
    },
    comments:{
        Task
        },
  emits: ['delete-task','toggle-reminder'],
}
</script>