<template>
    
    <header>
        <h1>{{title}}</h1>
        <Fbuton
        @toggle-add-task="$emit
        ('toggle-add-task')" 
        text="Pridaj ulohu" 
        color= "green"/>
 
         
     </header>
       
        
    
</template>

<script>
import Fbuton from './Fbuton'

export default {
   name: 'Mramik',
   props: {
     title:{String,
     default: 'Ahoj Svet',
     } 
   },
 components: {Fbuton
  
  }
}

</script>


<style lang="scss" scoped>

</style> 